import React, { Component } from 'react';
import { Container, Row, Col} from "reactstrap";

//Import Section Title
import SectionTitle from "../Common/section-title";
import FacilitiesBox from "./facilities-box";


class Facilities extends Component {
    constructor(props) {
        super(props);
        this.state = {
            smallFeatures1 : [
                { title : "Licenciamento", column: 5 },
                { title : "IPVA", column: 3 },
                { title : "Seguro", column: 4 },
            ],
            smallFeatures2 : [
                { title : "Solicite outros serviços", column: 12 },
            ]
        }
    }

    render() {
        return (
            <React.Fragment>
                <section className="section bg-light" id="features">
                    <Container>
                    <SectionTitle
                        subtitle="FACILIDADES"
                        title="Conheça nossas facilidades"
                        desc="Consulte ou solicite serviços pela plataforma, realize o pegamento e receba a documentação em casa."
                    />               

                        <Row>
                            <Col lg={5} className="text-center">
                                <div>
                                    <FacilitiesBox icon="credit-card" title="Consulte e parcele em até 12x" smallFeatures={this.state.smallFeatures1} desc="Consulte os débitos on-line e realize o pagamento em até 12 vezes no cartão de crédito." link="#" />
                                </div>
                            </Col>

                            <Col lg={5} className="ml-lg-auto text-center">
                                <div className="mt-4 mt-lg-0">
                                <FacilitiesBox icon="message-square" title="Solicite serviços" smallFeatures={this.state.smallFeatures2} desc="Solicite serviços pela plataforma e a nossa equipe lhe ajudará em todo o processo." link="#" />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                    
                </section>
            </React.Fragment>
        );
    }
}

export default Facilities;