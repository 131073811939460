import React, { Component } from 'react';
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

//Import Section Title
import SectionTitle from "../Common/section-title";
import ServiceBox from "./services-box";

class Services extends Component {
    constructor(props) {
        super(props);
        this.state = {
            services : [
                { icon : "thumbs-up" , title : "Licenciamento"                  , desc : "Consulte dos seus débitos de forma gratuita e realize o licenciamento on-line do seu veículo." },
                { icon : "edit"      , title : "Recurso de multa"               , desc : "Se você foi multado, consulte-nos e solicite um orçamento para realizar o recurso da multa." },
                { icon : "repeat"    , title : "Transferência de propriedade"   , desc : "Realize de maneira simples e rápida a transferência do seu veículo." },

                { icon : "copy"      , title : "Segunda via de CRLV"            , desc : "Solicite a segunda via do CRLV (Certificado de registro e licenciamento do veículo). Porte obrigatório!" },
                { icon : "refresh-cw", title : "Atualização do CRV"             , desc : "Consulte-nos para transferência de propriedade do veículo, se faz necessário a liberação e atualização do CRV." },
                { icon : "grid"      , title : "Características do veículo"     , desc : "Alteração nas características de fabricação do veículo: cor, combustível, blindagem e outros, solicite um novo CRV." },

                { icon : "truck"     , title : "Regularização de chassi e motor", desc : "Alterações no motor ou chassi, será necessário a alteração dos dados no documento. Consulte-nos." },
                { icon : "flag"      , title : "1º emplacamento - 0 KM"         , desc : "Realize o processo de emplacamento solicitando pela plataforma." },
                { icon : "sliders"   , title : "Regularização de suspensão"     , desc : "Em caso de alteração da suspensão do veículo, como rebaixamento, realize a regularização. Solicite!" },

                { icon : "unlock"    , title : "Desbloqueio de sinistro"        , desc : "Veículos classificados como 'média monta' são impedidos de circular ou transferir e lincenciar. Solicite o desbloqueio!" },
                { icon : "frown"     , title : "Desbloqueio de óbito"           , desc : "Caso o veículo esteja bloqueado por óbito do proprietário, acesse e plataforma e inicie o processo de desbloqueio." },
                { icon : "crop"      , title : "2ª via de placa ou lacres"      , desc : "Solicite a 2ª via de placa do veículo e/ou lacre de fixação da placa por motivo de furto, roubo, perda ou dano." },                


            ]
        }
    }
    
    render() {
        return (
            <React.Fragment>
                <section className="section" id="services">
                    <Container>
                    <SectionTitle
                        subtitle="SERVIÇOS"
                        title="Conheça nossos serviços"
                        desc="Alguns serviços são on-line outros podem ser solicitados a nossa equipe"
                    />

                        <Row>
                            <ServiceBox services={this.state.services} />
                        </Row>
                        

                        <Row className="mt-4">
                            <Col lg={12}>
                                <div className="text-center">
                                    <Link to="#" className="btn btn-outline-primary">Registrar</Link>
                                </div>
                            </Col>
                        </Row>
                        
                    </Container>
                    
                </section>
            </React.Fragment>
        );
    }
}

export default Services;