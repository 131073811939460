import React, { Component } from 'react';
import {
    Container,
    Row,
    Col
} from "reactstrap";

class Partners extends Component {

    render() {
        return (
            <React.Fragment>
                <section className="section bg-primary" id="partners">
                    <Container>
                        <Row className="justify-content-center mb-5">
                            <Col lg={7}>
                                <div className="text-center text-white-50">
                                    <h4 className="text-white">Para você despachante, torne-se nosso parceiro e cresça com a gente.</h4>
                                    <p>Efetue seu cadastro na plataforma e torne-se apto a receber serviços pelo DESPACHEI de qualquer lugar do Brasil.</p>
                                </div>
                            </Col>
                        </Row>

                        <Row>
                    
                            <div className="col-lg-12">
                                <div className="text-center mt-4">
                                    <a href="/" className="btn btn-outline-warning">Quero me tornar um parceiro</a>
                                </div>
                            </div>
                        </Row>
                    </Container>

                </section>
            </React.Fragment>
        );
    }
}

export default Partners;