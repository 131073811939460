import React, { Component } from 'react';
import { Container, Row, Col, Form, Input, Button } from "reactstrap";

class Messages extends Component {
    
    render() {
        const textAreaStyle = {
            height: '100%'
          } 
        return (
            <React.Fragment>
                <section className="section" id="contact">
                    <Container>
                        <Row className="justify-content-center">
                            <Col lg={8}>
                                <div className="text-center mb-5">
                                    <h5 className="text-primary text-uppercase small-title">CONTATO</h5>
                                    <h4 className="mb-3">Envie sua mensagem</h4>
                                    <p>Envie-nos um email com a sua solicitação e entraremos em contato</p>
                                </div>
                            </Col>

                            <Col xl={8} lg={10}>
                                <div className="text-center">
                                    <div className="subscribe">
                                        <Form>
                                            <Row>
                                                <Col md={12}>
                                                    <div>
                                                        <Input type="text" className="form-control mb-2" placeholder="Digite seu nome"/>
                                                    </div>
                                                </Col>
                                                <Col md={12}>
                                                    <div>
                                                        <Input style={textAreaStyle} type="textarea" rows={4} className="form-control mb-2" placeholder="Digite sua mensagem"/>
                                                    </div>
                                                </Col>
                                                <Col md={9}>
                                                    <div>
                                                        <Input type="email" className="form-control" placeholder="Digite seu e-mail"/>
                                                    </div>
                                                </Col> 
                                                <Col md={3}>
                                                    <div className="mt-3 mt-md-0">
                                                        <Button type="submit" color="primary" block>Enviar</Button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        
                    </Container>
                    
                </section>
            </React.Fragment>
        );
    }
}

export default Messages;