import React, { Component } from 'react';
import { Container, Row, Col} from "reactstrap";

//Import Section Title
import SectionTitle from "../Common/section-title";

class Register extends Component {

    render() {
        return (
            <React.Fragment>
                <section className="section bg-light" id="register">
                    <Container>
                    <SectionTitle
                        subtitle="REGISTRAR"
                        title="Seja um usuário da plataforma"
                        desc="Registre-se e acesse a plataforma para consultar, solicitar e parcelar seus débitos e serviços"
                    />
                        

                        <Row>
                            <Col lg={12}>
                                <div className="text-center mt-4">
                                    <a href="/" className="btn btn-primary">Registrar</a>
                                </div> 
                            </Col>
                        </Row>
                    </Container>
                    
                </section>
            </React.Fragment>
        );
    }
}

export default Register;