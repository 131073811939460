import React, { Component } from 'react';

import NavbarPage from "../../components/Navbar/Navbar_Page";
import Section from './section';
import Services from "../../components/Services/services";
import Facilities from "../../components/Facilities/facilities";
import Partners from '../../components/Partners/partners';
import Register from "../../components/Register/register";
import Messages from "../../components/Messages/messages";
import Footer from "../../components/Footer/footer";

class Index extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            navItems: [
                { id: 1, idnm: "home"    , navheading: "Início"     },
                { id: 2, idnm: "services", navheading: "Serviços"   },
                { id: 3, idnm: "features", navheading: "Facilidades"},
                { id: 4, idnm: "partners", navheading: "Parceiros"  },
                { id: 5, idnm: "contact", navheading:  "Contato"    },
                { id: 6, idnm: "register", navheading: "Registrar"  }
            ],
            pos:      document.documentElement.scrollTop,
            imglight: true,
            navClass: "",
            width:    window.innerWidth,
            height:   window.innerHeight
        };
    }

    updateWindowDimensions = () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
        this.scrollNavigation();
    };
  

    componentDidMount() {
        this.updateWindowDimensions();

        window.addEventListener("scroll", this.scrollNavigation, true);                
        window.addEventListener("resize", this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.scrollNavigation, true);
        window.removeEventListener("resize", this.updateWindowDimensions);
    }

    scrollNavigation = () => {        
        var scrollup = document.documentElement.scrollTop;
        if (scrollup > this.state.pos) {
            this.setState({ navClass: "nav-sticky", imglight: false });
        }
        else {
            if (this.state.width < 991) {
                this.setState({ navClass: "nav-sticky", imglight: false });  
            }
            else {
                this.setState({ navClass: "", imglight: true });
            }
            
        }
    };

    render() {
        return (
            <React.Fragment>

                {/* Importing Navbar */}
                <NavbarPage navItems={this.state.navItems} navClass={this.state.navClass} imglight={this.state.imglight} />

                {/* Importing section */}
                <Section />

                {/* Importing Service */}
                <Services />

                {/* Importing Features */}
                <Facilities />

                {/* Importing Partners */}
                <Partners />

                {/* Importing Sunscribe */}
                <Messages />

                {/* Importing Register */}
                <Register />                

                {/* Importing Footer */}
                <Footer />
            </React.Fragment>
        );
    }
}

export default Index;