import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Container, Row, Col, Form, FormGroup, Input, Button, Label, Card } from "reactstrap";


class Section extends Component {

    render() {
        return (
            <React.Fragment>
                <section className="hero-section-2 bg-primary" id="home">
                    <Container>
                        <Row className="vertical-content justify-content-center">
                            <Col lg={6}>
                                <div className="hero-wrapper mb-4">
                                    <p className="font-16 text-white text-uppercase">PLATAFORMA DIGITAL DE SEVIÇOS DE DESPACHANTE</p>
                                    <h1 className="hero-title text-white mb-4">Solicite nossos serviços de qualquer lugar do Brasil <span className="text-primary">Despachei</span></h1>

                                    <p className="text-white-50">Uma plataforma digital, simples e eficiente. Para que você possa solicitar pelo seu smartphone serviços de despachante</p>

                                    <div className="mt-4">
                                        <Link to="#" className="btn btn-register mt-2 mr-2">Registre-se</Link>
                                        <Link to="#" className="btn btn-outline-warning mt-2 mr-2 ml-1">Torne-se nosso parceiro</Link>
                                    </div>
                                </div>

                            </Col>

                            <Col lg={{ size: 4, offset: 2 }}>
                                <Card className="mx-auto p-4 rounded mt-5 mt-lg-0">
                                    <div className="text-center">
                                        <p className="text-muted mb-2 font-13 text-uppercase">REALIZE UMA CONSULTA</p>
                                        <h5 className="form-title mb-4">CONSULTE SEU VEÍCULO</h5>
                                    </div>
                                    <Form className="registration-form">
                                        <FormGroup className="mb-4">
                                            <Label className="font-15">Nome*</Label>
                                            <Input type="text" className="form-control" id="name" placeholder="" required />
                                        </FormGroup>
                                        <FormGroup className="mb-4">
                                            <Label className="font-15">E-mail*</Label>
                                            <Input type="email" className="form-control" id="email" placeholder="" required />
                                        </FormGroup>
                                        <FormGroup className="mb-3">
                                            <Label className="font-15">Placa*</Label>
                                            <Input type="password" className="form-control" id="password" placeholder="" required />
                                        </FormGroup>
                                        <Button className="font-16 mt-4" type="submit" color="primary" block>Consultar</Button>
                                    </Form>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </React.Fragment>
        );
    }
}

export default Section;